@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@font-face {
  font-family: "Gotham Rounded";
  src: url("/assets/fonts/GothamRounded-Book.eot");
  src: local("Gotham Rounded Book"), local("GothamRounded-Book"), url("/assets/fonts/GothamRounded-Book.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/GothamRounded-Book.woff2") format("woff2"), url("/assets/fonts/GothamRounded-Book.woff") format("woff"), url("/assets/fonts/GothamRounded-Book.ttf") format("truetype"), url("/assets/fonts/GothamRounded-Book.svg#GothamRounded-Book") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("/assets/fonts/GothamRounded-Medium.eot");
  src: local("Gotham Rounded Medium"), local("GothamRounded-Medium"), url("/assets/fonts/GothamRounded-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/GothamRounded-Medium.woff2") format("woff2"), url("/assets/fonts/GothamRounded-Medium.woff") format("woff"), url("/assets/fonts/GothamRounded-Medium.ttf") format("truetype"), url("/assets/fonts/GothamRounded-Medium.svg#GothamRounded-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
.fp {
  position: relative;
  display: inline-block;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.fp:before {
  content: " ";
}

.fp {
  line-height: 1em;
  width: 1.3333333333em;
}
.fp.fp-rounded {
  border-radius: 0.1666666667em;
}
.fp.fp-md {
  line-height: 1.5em;
  width: 2em;
}
.fp.fp-md.fp-rounded {
  border-radius: 0.25em;
}
.fp.fp-lg {
  line-height: 2em;
  width: 2.6666666667em;
}
.fp.fp-lg.fp-rounded {
  border-radius: 0.25em;
}

.fp-ac {
  background-image: url("../flags/4x3/ac.svg");
}

.fp-ad {
  background-image: url("../flags/4x3/ad.svg");
}

.fp-ae {
  background-image: url("../flags/4x3/ae.svg");
}

.fp-af {
  background-image: url("../flags/4x3/af.svg");
}

.fp-ag {
  background-image: url("../flags/4x3/ag.svg");
}

.fp-ai {
  background-image: url("../flags/4x3/ai.svg");
}

.fp-al {
  background-image: url("../flags/4x3/al.svg");
}

.fp-am {
  background-image: url("../flags/4x3/am.svg");
}

.fp-ao {
  background-image: url("../flags/4x3/ao.svg");
}

.fp-ar {
  background-image: url("../flags/4x3/ar.svg");
}

.fp-as {
  background-image: url("../flags/4x3/as.svg");
}

.fp-at {
  background-image: url("../flags/4x3/at.svg");
}

.fp-au {
  background-image: url("../flags/4x3/au.svg");
}

.fp-aw {
  background-image: url("../flags/4x3/aw.svg");
}

.fp-ax {
  background-image: url("../flags/4x3/ax.svg");
}

.fp-az {
  background-image: url("../flags/4x3/az.svg");
}

.fp-ba {
  background-image: url("../flags/4x3/ba.svg");
}

.fp-bb {
  background-image: url("../flags/4x3/bb.svg");
}

.fp-bd {
  background-image: url("../flags/4x3/bd.svg");
}

.fp-be {
  background-image: url("../flags/4x3/be.svg");
}

.fp-bf {
  background-image: url("../flags/4x3/bf.svg");
}

.fp-bg {
  background-image: url("../flags/4x3/bg.svg");
}

.fp-bh {
  background-image: url("../flags/4x3/bh.svg");
}

.fp-bi {
  background-image: url("../flags/4x3/bi.svg");
}

.fp-bj {
  background-image: url("../flags/4x3/bj.svg");
}

.fp-bl {
  background-image: url("../flags/4x3/bl.svg");
}

.fp-bm {
  background-image: url("../flags/4x3/bm.svg");
}

.fp-bn {
  background-image: url("../flags/4x3/bn.svg");
}

.fp-bo {
  background-image: url("../flags/4x3/bo.svg");
}

.fp-bq {
  background-image: url("../flags/4x3/bq.svg");
}

.fp-br {
  background-image: url("../flags/4x3/br.svg");
}

.fp-bs {
  background-image: url("../flags/4x3/bs.svg");
}

.fp-bt {
  background-image: url("../flags/4x3/bt.svg");
}

.fp-bv {
  background-image: url("../flags/4x3/bv.svg");
}

.fp-bw {
  background-image: url("../flags/4x3/bw.svg");
}

.fp-by {
  background-image: url("../flags/4x3/by.svg");
}

.fp-bz {
  background-image: url("../flags/4x3/bz.svg");
}

.fp-ca {
  background-image: url("../flags/4x3/ca.svg");
}

.fp-cc {
  background-image: url("../flags/4x3/cc.svg");
}

.fp-cd {
  background-image: url("../flags/4x3/cd.svg");
}

.fp-cf {
  background-image: url("../flags/4x3/cf.svg");
}

.fp-cg {
  background-image: url("../flags/4x3/cg.svg");
}

.fp-ch {
  background-image: url("../flags/4x3/ch.svg");
}

.fp-ci {
  background-image: url("../flags/4x3/ci.svg");
}

.fp-ck {
  background-image: url("../flags/4x3/ck.svg");
}

.fp-cl {
  background-image: url("../flags/4x3/cl.svg");
}

.fp-cm {
  background-image: url("../flags/4x3/cm.svg");
}

.fp-cn {
  background-image: url("../flags/4x3/cn.svg");
}

.fp-co {
  background-image: url("../flags/4x3/co.svg");
}

.fp-cr {
  background-image: url("../flags/4x3/cr.svg");
}

.fp-cu {
  background-image: url("../flags/4x3/cu.svg");
}

.fp-cv {
  background-image: url("../flags/4x3/cv.svg");
}

.fp-cw {
  background-image: url("../flags/4x3/cw.svg");
}

.fp-cx {
  background-image: url("../flags/4x3/cx.svg");
}

.fp-cy {
  background-image: url("../flags/4x3/cy.svg");
}

.fp-cz {
  background-image: url("../flags/4x3/cz.svg");
}

.fp-de {
  background-image: url("../flags/4x3/de.svg");
}

.fp-dj {
  background-image: url("../flags/4x3/dj.svg");
}

.fp-dk {
  background-image: url("../flags/4x3/dk.svg");
}

.fp-dm {
  background-image: url("../flags/4x3/dm.svg");
}

.fp-do {
  background-image: url("../flags/4x3/do.svg");
}

.fp-dz {
  background-image: url("../flags/4x3/dz.svg");
}

.fp-ec {
  background-image: url("../flags/4x3/ec.svg");
}

.fp-ee {
  background-image: url("../flags/4x3/ee.svg");
}

.fp-eg {
  background-image: url("../flags/4x3/eg.svg");
}

.fp-eh {
  background-image: url("../flags/4x3/eh.svg");
}

.fp-er {
  background-image: url("../flags/4x3/er.svg");
}

.fp-es {
  background-image: url("../flags/4x3/es.svg");
}

.fp-et {
  background-image: url("../flags/4x3/et.svg");
}

.fp-eu {
  background-image: url("../flags/4x3/eu.svg");
}

.fp-fi {
  background-image: url("../flags/4x3/fi.svg");
}

.fp-fj {
  background-image: url("../flags/4x3/fj.svg");
}

.fp-fk {
  background-image: url("../flags/4x3/fk.svg");
}

.fp-fm {
  background-image: url("../flags/4x3/fm.svg");
}

.fp-fo {
  background-image: url("../flags/4x3/fo.svg");
}

.fp-fr {
  background-image: url("../flags/4x3/fr.svg");
}

.fp-ga {
  background-image: url("../flags/4x3/ga.svg");
}

.fp-gb-eng {
  background-image: url("../flags/4x3/gb-eng.svg");
}

.fp-gb-nir {
  background-image: url("../flags/4x3/gb-nir.svg");
}

.fp-gb-sct {
  background-image: url("../flags/4x3/gb-sct.svg");
}

.fp-gb-wls {
  background-image: url("../flags/4x3/gb-wls.svg");
}

.fp-gb-zet {
  background-image: url("../flags/4x3/gb-zet.svg");
}

.fp-gb {
  background-image: url("../flags/4x3/gb.svg");
}

.fp-gd {
  background-image: url("../flags/4x3/gd.svg");
}

.fp-ge {
  background-image: url("../flags/4x3/ge.svg");
}

.fp-gf {
  background-image: url("../flags/4x3/gf.svg");
}

.fp-gg {
  background-image: url("../flags/4x3/gg.svg");
}

.fp-gh {
  background-image: url("../flags/4x3/gh.svg");
}

.fp-gi {
  background-image: url("../flags/4x3/gi.svg");
}

.fp-gl {
  background-image: url("../flags/4x3/gl.svg");
}

.fp-gm {
  background-image: url("../flags/4x3/gm.svg");
}

.fp-gn {
  background-image: url("../flags/4x3/gn.svg");
}

.fp-gp {
  background-image: url("../flags/4x3/gp.svg");
}

.fp-gq {
  background-image: url("../flags/4x3/gq.svg");
}

.fp-gr {
  background-image: url("../flags/4x3/gr.svg");
}

.fp-gs {
  background-image: url("../flags/4x3/gs.svg");
}

.fp-gt {
  background-image: url("../flags/4x3/gt.svg");
}

.fp-gu {
  background-image: url("../flags/4x3/gu.svg");
}

.fp-gw {
  background-image: url("../flags/4x3/gw.svg");
}

.fp-gy {
  background-image: url("../flags/4x3/gy.svg");
}

.fp-hk {
  background-image: url("../flags/4x3/hk.svg");
}

.fp-hm {
  background-image: url("../flags/4x3/hm.svg");
}

.fp-hn {
  background-image: url("../flags/4x3/hn.svg");
}

.fp-hr {
  background-image: url("../flags/4x3/hr.svg");
}

.fp-ht {
  background-image: url("../flags/4x3/ht.svg");
}

.fp-hu {
  background-image: url("../flags/4x3/hu.svg");
}

.fp-id {
  background-image: url("../flags/4x3/id.svg");
}

.fp-ie {
  background-image: url("../flags/4x3/ie.svg");
}

.fp-il {
  background-image: url("../flags/4x3/il.svg");
}

.fp-im {
  background-image: url("../flags/4x3/im.svg");
}

.fp-in {
  background-image: url("../flags/4x3/in.svg");
}

.fp-io {
  background-image: url("../flags/4x3/io.svg");
}

.fp-iq {
  background-image: url("../flags/4x3/iq.svg");
}

.fp-ir {
  background-image: url("../flags/4x3/ir.svg");
}

.fp-is {
  background-image: url("../flags/4x3/is.svg");
}

.fp-it {
  background-image: url("../flags/4x3/it.svg");
}

.fp-je {
  background-image: url("../flags/4x3/je.svg");
}

.fp-jm {
  background-image: url("../flags/4x3/jm.svg");
}

.fp-jo {
  background-image: url("../flags/4x3/jo.svg");
}

.fp-jp {
  background-image: url("../flags/4x3/jp.svg");
}

.fp-ke {
  background-image: url("../flags/4x3/ke.svg");
}

.fp-kg {
  background-image: url("../flags/4x3/kg.svg");
}

.fp-kh {
  background-image: url("../flags/4x3/kh.svg");
}

.fp-ki {
  background-image: url("../flags/4x3/ki.svg");
}

.fp-km {
  background-image: url("../flags/4x3/km.svg");
}

.fp-kn {
  background-image: url("../flags/4x3/kn.svg");
}

.fp-kp {
  background-image: url("../flags/4x3/kp.svg");
}

.fp-kr {
  background-image: url("../flags/4x3/kr.svg");
}

.fp-kw {
  background-image: url("../flags/4x3/kw.svg");
}

.fp-ky {
  background-image: url("../flags/4x3/ky.svg");
}

.fp-kz {
  background-image: url("../flags/4x3/kz.svg");
}

.fp-la {
  background-image: url("../flags/4x3/la.svg");
}

.fp-lb {
  background-image: url("../flags/4x3/lb.svg");
}

.fp-lc {
  background-image: url("../flags/4x3/lc.svg");
}

.fp-lgbt {
  background-image: url("../flags/4x3/lgbt.svg");
}

.fp-li {
  background-image: url("../flags/4x3/li.svg");
}

.fp-lk {
  background-image: url("../flags/4x3/lk.svg");
}

.fp-lr {
  background-image: url("../flags/4x3/lr.svg");
}

.fp-ls {
  background-image: url("../flags/4x3/ls.svg");
}

.fp-lt {
  background-image: url("../flags/4x3/lt.svg");
}

.fp-lu {
  background-image: url("../flags/4x3/lu.svg");
}

.fp-lv {
  background-image: url("../flags/4x3/lv.svg");
}

.fp-ly {
  background-image: url("../flags/4x3/ly.svg");
}

.fp-ma {
  background-image: url("../flags/4x3/ma.svg");
}

.fp-mc {
  background-image: url("../flags/4x3/mc.svg");
}

.fp-md {
  background-image: url("../flags/4x3/md.svg");
}

.fp-me {
  background-image: url("../flags/4x3/me.svg");
}

.fp-mf {
  background-image: url("../flags/4x3/mf.svg");
}

.fp-mg {
  background-image: url("../flags/4x3/mg.svg");
}

.fp-mh {
  background-image: url("../flags/4x3/mh.svg");
}

.fp-mk {
  background-image: url("../flags/4x3/mk.svg");
}

.fp-ml {
  background-image: url("../flags/4x3/ml.svg");
}

.fp-mm {
  background-image: url("../flags/4x3/mm.svg");
}

.fp-mn {
  background-image: url("../flags/4x3/mn.svg");
}

.fp-mo {
  background-image: url("../flags/4x3/mo.svg");
}

.fp-mp {
  background-image: url("../flags/4x3/mp.svg");
}

.fp-mq {
  background-image: url("../flags/4x3/mq.svg");
}

.fp-mr {
  background-image: url("../flags/4x3/mr.svg");
}

.fp-ms {
  background-image: url("../flags/4x3/ms.svg");
}

.fp-mt {
  background-image: url("../flags/4x3/mt.svg");
}

.fp-mu {
  background-image: url("../flags/4x3/mu.svg");
}

.fp-mv {
  background-image: url("../flags/4x3/mv.svg");
}

.fp-mw {
  background-image: url("../flags/4x3/mw.svg");
}

.fp-mx {
  background-image: url("../flags/4x3/mx.svg");
}

.fp-my {
  background-image: url("../flags/4x3/my.svg");
}

.fp-mz {
  background-image: url("../flags/4x3/mz.svg");
}

.fp-na {
  background-image: url("../flags/4x3/na.svg");
}

.fp-nc {
  background-image: url("../flags/4x3/nc.svg");
}

.fp-ne {
  background-image: url("../flags/4x3/ne.svg");
}

.fp-nf {
  background-image: url("../flags/4x3/nf.svg");
}

.fp-ng {
  background-image: url("../flags/4x3/ng.svg");
}

.fp-ni {
  background-image: url("../flags/4x3/ni.svg");
}

.fp-nl {
  background-image: url("../flags/4x3/nl.svg");
}

.fp-no {
  background-image: url("../flags/4x3/no.svg");
}

.fp-np {
  background-image: url("../flags/4x3/np.svg");
}

.fp-nr {
  background-image: url("../flags/4x3/nr.svg");
}

.fp-nu {
  background-image: url("../flags/4x3/nu.svg");
}

.fp-nz {
  background-image: url("../flags/4x3/nz.svg");
}

.fp-om {
  background-image: url("../flags/4x3/om.svg");
}

.fp-pa {
  background-image: url("../flags/4x3/pa.svg");
}

.fp-pe {
  background-image: url("../flags/4x3/pe.svg");
}

.fp-pf {
  background-image: url("../flags/4x3/pf.svg");
}

.fp-pg {
  background-image: url("../flags/4x3/pg.svg");
}

.fp-ph {
  background-image: url("../flags/4x3/ph.svg");
}

.fp-pk {
  background-image: url("../flags/4x3/pk.svg");
}

.fp-pl {
  background-image: url("../flags/4x3/pl.svg");
}

.fp-pm {
  background-image: url("../flags/4x3/pm.svg");
}

.fp-pn {
  background-image: url("../flags/4x3/pn.svg");
}

.fp-pr {
  background-image: url("../flags/4x3/pr.svg");
}

.fp-ps {
  background-image: url("../flags/4x3/ps.svg");
}

.fp-pt {
  background-image: url("../flags/4x3/pt.svg");
}

.fp-pw {
  background-image: url("../flags/4x3/pw.svg");
}

.fp-py {
  background-image: url("../flags/4x3/py.svg");
}

.fp-qa {
  background-image: url("../flags/4x3/qa.svg");
}

.fp-re {
  background-image: url("../flags/4x3/re.svg");
}

.fp-ro {
  background-image: url("../flags/4x3/ro.svg");
}

.fp-rs {
  background-image: url("../flags/4x3/rs.svg");
}

.fp-ru {
  background-image: url("../flags/4x3/ru.svg");
}

.fp-rw {
  background-image: url("../flags/4x3/rw.svg");
}

.fp-sa {
  background-image: url("../flags/4x3/sa.svg");
}

.fp-sb {
  background-image: url("../flags/4x3/sb.svg");
}

.fp-sc {
  background-image: url("../flags/4x3/sc.svg");
}

.fp-sd {
  background-image: url("../flags/4x3/sd.svg");
}

.fp-se {
  background-image: url("../flags/4x3/se.svg");
}

.fp-sg {
  background-image: url("../flags/4x3/sg.svg");
}

.fp-sh {
  background-image: url("../flags/4x3/sh.svg");
}

.fp-si {
  background-image: url("../flags/4x3/si.svg");
}

.fp-sj {
  background-image: url("../flags/4x3/sj.svg");
}

.fp-sk {
  background-image: url("../flags/4x3/sk.svg");
}

.fp-sl {
  background-image: url("../flags/4x3/sl.svg");
}

.fp-sm {
  background-image: url("../flags/4x3/sm.svg");
}

.fp-sn {
  background-image: url("../flags/4x3/sn.svg");
}

.fp-so {
  background-image: url("../flags/4x3/so.svg");
}

.fp-sr {
  background-image: url("../flags/4x3/sr.svg");
}

.fp-ss {
  background-image: url("../flags/4x3/ss.svg");
}

.fp-st {
  background-image: url("../flags/4x3/st.svg");
}

.fp-sv {
  background-image: url("../flags/4x3/sv.svg");
}

.fp-sx {
  background-image: url("../flags/4x3/sx.svg");
}

.fp-sy {
  background-image: url("../flags/4x3/sy.svg");
}

.fp-sz {
  background-image: url("../flags/4x3/sz.svg");
}

.fp-ta {
  background-image: url("../flags/4x3/ta.svg");
}

.fp-tc {
  background-image: url("../flags/4x3/tc.svg");
}

.fp-td {
  background-image: url("../flags/4x3/td.svg");
}

.fp-tf {
  background-image: url("../flags/4x3/tf.svg");
}

.fp-tg {
  background-image: url("../flags/4x3/tg.svg");
}

.fp-th {
  background-image: url("../flags/4x3/th.svg");
}

.fp-tj {
  background-image: url("../flags/4x3/tj.svg");
}

.fp-tk {
  background-image: url("../flags/4x3/tk.svg");
}

.fp-tl {
  background-image: url("../flags/4x3/tl.svg");
}

.fp-tm {
  background-image: url("../flags/4x3/tm.svg");
}

.fp-tn {
  background-image: url("../flags/4x3/tn.svg");
}

.fp-to {
  background-image: url("../flags/4x3/to.svg");
}

.fp-tr {
  background-image: url("../flags/4x3/tr.svg");
}

.fp-tt {
  background-image: url("../flags/4x3/tt.svg");
}

.fp-tv {
  background-image: url("../flags/4x3/tv.svg");
}

.fp-tw {
  background-image: url("../flags/4x3/tw.svg");
}

.fp-tz {
  background-image: url("../flags/4x3/tz.svg");
}

.fp-ua {
  background-image: url("../flags/4x3/ua.svg");
}

.fp-ug {
  background-image: url("../flags/4x3/ug.svg");
}

.fp-um {
  background-image: url("../flags/4x3/um.svg");
}

.fp-us {
  background-image: url("../flags/4x3/us.svg");
}

.fp-uy {
  background-image: url("../flags/4x3/uy.svg");
}

.fp-uz {
  background-image: url("../flags/4x3/uz.svg");
}

.fp-va {
  background-image: url("../flags/4x3/va.svg");
}

.fp-vc {
  background-image: url("../flags/4x3/vc.svg");
}

.fp-ve {
  background-image: url("../flags/4x3/ve.svg");
}

.fp-vg {
  background-image: url("../flags/4x3/vg.svg");
}

.fp-vi {
  background-image: url("../flags/4x3/vi.svg");
}

.fp-vn {
  background-image: url("../flags/4x3/vn.svg");
}

.fp-vu {
  background-image: url("../flags/4x3/vu.svg");
}

.fp-wf {
  background-image: url("../flags/4x3/wf.svg");
}

.fp-ws {
  background-image: url("../flags/4x3/ws.svg");
}

.fp-xk {
  background-image: url("../flags/4x3/xk.svg");
}

.fp-ye {
  background-image: url("../flags/4x3/ye.svg");
}

.fp-yt {
  background-image: url("../flags/4x3/yt.svg");
}

.fp-za {
  background-image: url("../flags/4x3/za.svg");
}

.fp-zm {
  background-image: url("../flags/4x3/zm.svg");
}

.fp-zw {
  background-image: url("../flags/4x3/zw.svg");
}

body {
  font-size: 10px;
  line-height: 18px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  overflow-x: hidden;
  direction: ltr;
  background-color: #efefef;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: "Inter", sans-serif;
  color: #333333;
}
@media screen and (min-width: 320px) {
  body {
    font-size: 14px;
    line-height: calc(14px + 10px);
  }
}
body.rtl {
  direction: rtl;
}

a, a:hover {
  text-decoration: inherit;
  color: inherit;
}

.ltr-only {
  /*rtl:ignore*/
  direction: ltr;
}

.rtl-phonenumber {
  /*rtl:ignore*/
  direction: ltr;
  /*rtl:ignore*/
  text-align: right;
}

button {
  border: none;
  outline: 0;
}
button:active, button:hover {
  box-shadow: none;
  outline: 0;
}
button::-moz-focus-inner {
  border: 0;
}

/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: "Inter", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px "Inter", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px "Inter", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px "Inter", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px "Inter", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px "Inter", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px "Inter", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px "Inter", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px "Inter", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px "Inter", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px "Inter", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px "Inter", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px "Inter", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px "Inter", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px "Inter", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: "Inter", sans-serif;
}

.mat-card {
  font-family: "Inter", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: "Inter", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: "Inter", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: "Inter", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px "Inter", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px "Inter", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: "Inter", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: "Inter", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: "Inter", sans-serif;
}

.mat-select {
  font-family: "Inter", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: "Inter", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: "Inter", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: "Inter", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px "Inter", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: "Inter", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: "Inter", sans-serif;
}

.mat-list-option {
  font-family: "Inter", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px "Inter", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: "Inter", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #009688;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #00bfa5;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #009688;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #00bfa5;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-content {
  color: white;
  background: #009688;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #00bfa5;
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #009688;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #00bfa5;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #009688;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #00bfa5;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #009688;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #00bfa5;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #009688;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #00bfa5;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #009688;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #00bfa5;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #009688;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #00bfa5;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(0, 150, 136, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 150, 136, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 150, 136, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #009688;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 150, 136, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 150, 136, 0.3);
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(0, 191, 165, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 191, 165, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 191, 165, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #00bfa5;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 191, 165, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 191, 165, 0.3);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #009688;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #00bfa5;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #009688;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #00bfa5;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #00bfa5;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #009688;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #00bfa5;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #009688;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #00bfa5;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #009688;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #00bfa5;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #009688;
}
.mat-icon.mat-accent {
  color: #00bfa5;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #009688;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #00bfa5;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
  background-color: #eeeeee;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #b2dfdb;
}

.mat-progress-bar-buffer {
  background-color: #b2dfdb;
}

.mat-progress-bar-fill::after {
  background-color: #009688;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b2dfdb;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b2dfdb;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #00bfa5;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #009688;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #00bfa5;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #009688;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #009688;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #00bfa5;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #00bfa5;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #009688;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #00bfa5;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #00bfa5;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 191, 165, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #00bfa5;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #009688;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 150, 136, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #009688;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #009688;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 150, 136, 0.2);
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #00bfa5;
}
.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.mat-accent .mat-slider-focus-ring {
  background-color: rgba(0, 191, 165, 0.2);
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #009688;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #00bfa5;
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(178, 223, 219, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #009688;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(178, 223, 219, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #00bfa5;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(178, 223, 219, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #009688;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(178, 223, 219, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #00bfa5;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #009688;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #00bfa5;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #00bfa5;
}

.mat-date-range-input-container {
  padding-left: 16px;
  padding-right: 32px;
}

.mat-date-range-input-separator-hidden + .mat-date-range-input-end-wrapper {
  max-width: 0;
}

.mat-date-range-input-end-wrapper input.uni-input {
  padding-right: 0;
  padding-left: 0;
}

div.mat-date-range-input-start-wrapper {
  max-width: none;
}

.mat-date-range-input-start-wrapper input.uni-input {
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: right;
}
.mat-date-range-input-start-wrapper input.uni-input:placeholder-shown {
  text-align: left;
}

.mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0 !important;
}

.mat-form-field-flex {
  display: block !important;
}

.mat-select-trigger {
  padding: 14px;
  box-sizing: border-box;
}

.mat-form-field.mat-form-field--link,
.mat-form-field.mat-form-field-type-mat-select.mat-form-field--link {
  padding: 0;
  border: 0;
  color: #000000;
}
.mat-form-field.mat-form-field--link .mat-select-placeholder,
.mat-form-field.mat-form-field--link .mat-form-field-hide-placeholder .mat-select-placeholder,
.mat-form-field.mat-form-field-type-mat-select.mat-form-field--link .mat-select-placeholder,
.mat-form-field.mat-form-field-type-mat-select.mat-form-field--link .mat-form-field-hide-placeholder .mat-select-placeholder {
  color: #000000 !important;
  -webkit-text-fill-color: #000000 !important;
  font-weight: 500;
}
.mat-form-field.mat-form-field--link .mat-select,
.mat-form-field.mat-form-field-type-mat-select.mat-form-field--link .mat-select {
  height: auto;
}
.mat-form-field.mat-form-field--link .mat-select-trigger,
.mat-form-field.mat-form-field-type-mat-select.mat-form-field--link .mat-select-trigger {
  display: flex;
  padding: 0;
}
.mat-form-field.mat-form-field--link .mat-select-value,
.mat-form-field.mat-form-field-type-mat-select.mat-form-field--link .mat-select-value {
  display: inline;
  max-width: initial;
  min-height: 16px;
}
.mat-form-field.mat-form-field--link .mat-select-arrow-wrapper,
.mat-form-field.mat-form-field-type-mat-select.mat-form-field--link .mat-select-arrow-wrapper {
  padding-left: 8px;
}
.mat-form-field.mat-form-field--link .mat-select .mat-select-arrow-wrapper .mat-select-arrow,
.mat-form-field.mat-form-field-type-mat-select.mat-form-field--link .mat-select .mat-select-arrow-wrapper .mat-select-arrow {
  margin: 2px;
  font-size: 10px;
  font-weight: 500;
}

.cdk-overlay-pane .mat-select-panel {
  margin-top: 0;
}

.mat-menu-panel {
  min-height: 48px !important;
  border-radius: 8px !important;
  padding: 8px 0px !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 24px 48px 0 rgba(0, 0, 0, 0.03), 0 10px 18px 0 rgba(0, 0, 0, 0.03), 0 5px 8px 0 rgba(0, 0, 0, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.04) !important;
}
.mat-menu-panel .mat-menu-content:not(:empty) {
  padding: 0 !important;
}
.mat-menu-panel .mat-menu-item {
  line-height: 20px;
  padding: 4px 12px;
  height: 28px;
}
.mat-menu-panel .mat-menu-item:hover, .mat-menu-panel .mat-menu-item:hover:not([disabled]), .mat-menu-panel .mat-menu-item.cdk-program-focused:not([disabled]), .mat-menu-panel .mat-menu-item.cdk-keyboard-focused:not([disabled]), .mat-menu-panel .mat-menu-item-highlighted:not([disabled]) {
  background: #f7f6f6;
  box-shadow: none !important;
}
.mat-menu-panel .mat-menu-item.btn, .mat-menu-panel .mat-menu-item.btn.focus, .mat-menu-panel .mat-menu-item.btn:focus {
  box-shadow: none;
}
.mat-menu-panel .mat-menu-item .uni-button {
  padding: 0 !important;
  height: fit-content !important;
}

.mat-form-field {
  font-size: 10px;
  line-height: 18px;
  width: 100%;
  line-height: inherit;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  color: #000000;
  background-color: transparent;
}
@media screen and (min-width: 320px) {
  .mat-form-field {
    font-size: 14px;
    line-height: calc(14px + 10px);
  }
}
.mat-form-field.mat-focused, .mat-form-field:focus-visible {
  border-color: #1d76fc;
  outline: none;
}
.mat-form-field:hover {
  border-color: #c2c2c2;
}
.mat-form-field:active::placeholder {
  color: #000000;
}
.mat-form-field.mat-form-field-invalid.ng-touched {
  border-color: #c9312c;
  color: #c9312c;
}
.mat-form-field.mat-form-field-type-mat-select {
  box-sizing: border-box;
  line-height: initial;
}
.mat-form-field.mat-form-field-disabled {
  color: #a3a3a3;
  background-color: #efefef;
  margin-top: -1px;
  padding-top: 1px;
}
.mat-form-field .mat-select-disabled .mat-select-value {
  color: inherit;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow-wrapper .mat-select-arrow::before {
  color: #898989;
}
.mat-form-field .mat-form-field-wrapper {
  margin: 0;
  padding-bottom: 0;
}
.mat-form-field .mat-form-field-infix {
  min-width: inherit;
  width: 100%;
  padding: 0;
  border: none;
}
.mat-form-field .mat-form-field-underline,
.mat-form-field .mat-form-field-label-wrapper,
.mat-form-field .mat-form-field-subscript-wrapper {
  display: none;
}
.mat-form-field .mat-input-element {
  caret-color: black !important;
}
.mat-form-field ::ng-deep input {
  font-size: 10px;
  line-height: 18px;
  width: 100%;
  padding: 11px 13px;
  border: 1px solid #a3a3a3;
  border-radius: 4px;
  color: #333333;
  caret-color: black;
  font-family: "Inter", sans-serif;
  transition: 0.2s all ease-in-out;
}
@media screen and (min-width: 320px) {
  .mat-form-field ::ng-deep input {
    font-size: 14px;
    line-height: calc(14px + 10px);
  }
}
.mat-form-field ::ng-deep input::placeholder {
  color: #6b6b6b;
}
.mat-form-field ::ng-deep input:focus-visible {
  border-color: #898989;
  outline: none;
}
.mat-form-field ::ng-deep input:active::placeholder {
  color: #000000;
}

.mat-autocomplete-panel .mat-option {
  height: unset;
  padding: 8px 16px;
  font-size: 12px;
  line-height: inherit;
  color: #333333;
}
@media screen and (min-width: 1200px) {
  .mat-autocomplete-panel .mat-option {
    font-size: 14px;
  }
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled):not(.mat-selected),
.mat-autocomplete-panel .mat-option:hover:not(.mat-option-disabled):not(.mat-selected) {
  background: #e8fcf3;
  color: #333333;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled),
.mat-autocomplete-panel .mat-option.mat-active,
.mat-autocomplete-panel .mat-pseudo-checkbox-checked {
  background: #ffffff;
  color: #333333;
  font-weight: 500;
}
.mat-autocomplete-panel .mat-option:focus-visible:not(.mat-option-disabled),
.mat-autocomplete-panel .mat-option:active:not(.mat-option-disabled) {
  background: #e8fcf3;
  color: #333333;
  font-weight: 500;
}
.mat-autocomplete-panel .mat-option-disabled {
  color: #a3a3a3;
  background-color: #efefef;
}

.mat-select {
  height: 42px;
}
.mat-select .mat-select-placeholder {
  font-size: 10px;
  line-height: 18px;
  color: #a3a3a3 !important;
  -webkit-text-fill-color: #a3a3a3 !important;
  line-height: 1 !important;
}
@media screen and (min-width: 320px) {
  .mat-select .mat-select-placeholder {
    font-size: 14px;
    line-height: calc(14px + 10px);
  }
}
.mat-select .mat-select-arrow-wrapper {
  display: block;
}
.mat-select .mat-select-arrow-wrapper .mat-select-arrow {
  border: 0;
  margin-right: 13px;
  font-weight: 400;
}
.mat-select .mat-select-arrow-wrapper .mat-select-arrow::before {
  font-family: "Font Awesome 6 Pro";
  content: "";
  color: #6b6b6b;
}
.mat-select[aria-owns] .mat-select-arrow::before {
  content: "";
}

.mat-select-panel {
  margin-top: 40px;
  padding: 8px;
}
.mat-select-panel.mat-select-panel--no-space {
  padding: 0;
}
.mat-select-panel .mat-option {
  position: relative;
  padding: 16px;
  height: auto !important;
}
.mat-select-panel .mat-option:not(:first-of-type) {
  border-top: 1px solid #efefef;
}

.mat-select-panel::-webkit-scrollbar,
.mat-autocomplete-panel::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.mat-select-panel::-webkit-scrollbar-track,
.mat-autocomplete-panel::-webkit-scrollbar-track {
  background-color: #efefef;
}
.mat-select-panel::-webkit-scrollbar-thumb,
.mat-autocomplete-panel::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
}
.mat-select-panel::-webkit-scrollbar-thumb:hover,
.mat-autocomplete-panel::-webkit-scrollbar-thumb:hover {
  background-color: #969696;
}
.mat-select-panel .mat-option.mat-active,
.mat-autocomplete-panel .mat-option.mat-active {
  background: #ffffff;
}
.mat-select-panel .mat-option.mat-selected.mat-active,
.mat-select-panel .mat-option.mat-active,
.mat-select-panel .mat-option.mat-selected,
.mat-select-panel .mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.mat-autocomplete-panel .mat-option.mat-selected.mat-active,
.mat-autocomplete-panel .mat-option.mat-active,
.mat-autocomplete-panel .mat-option.mat-selected,
.mat-autocomplete-panel .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  background: #e8fcf3;
  color: #171717;
  font-weight: 500;
}
.mat-select-panel .mat-option:not(.mat-active):hover,
.mat-select-panel .mat-option:not(.mat-active):focus-visible,
.mat-autocomplete-panel .mat-option:not(.mat-active):hover,
.mat-autocomplete-panel .mat-option:not(.mat-active):focus-visible {
  background: #e8fcf3;
  color: #333333;
}
.mat-select-panel .mat-option:not(.mat-active):hover .mat-option-text,
.mat-select-panel .mat-option:not(.mat-active):focus-visible .mat-option-text,
.mat-autocomplete-panel .mat-option:not(.mat-active):hover .mat-option-text,
.mat-autocomplete-panel .mat-option:not(.mat-active):focus-visible .mat-option-text {
  color: #333333;
}
.mat-select-panel .mat-option:hover .mat-pseudo-checkbox-checked,
.mat-select-panel .mat-option:hover .mat-pseudo-checkbox-indeterminate,
.mat-select-panel .mat-option:focus-visible .mat-pseudo-checkbox-checked,
.mat-select-panel .mat-option:focus-visible .mat-pseudo-checkbox-indeterminate,
.mat-autocomplete-panel .mat-option:hover .mat-pseudo-checkbox-checked,
.mat-autocomplete-panel .mat-option:hover .mat-pseudo-checkbox-indeterminate,
.mat-autocomplete-panel .mat-option:focus-visible .mat-pseudo-checkbox-checked,
.mat-autocomplete-panel .mat-option:focus-visible .mat-pseudo-checkbox-indeterminate {
  background-color: #18c36d;
}
.mat-select-panel .mat-option:hover .mat-pseudo-checkbox,
.mat-select-panel .mat-option:focus-visible .mat-pseudo-checkbox,
.mat-autocomplete-panel .mat-option:hover .mat-pseudo-checkbox,
.mat-autocomplete-panel .mat-option:focus-visible .mat-pseudo-checkbox {
  color: #18c36d;
}
.mat-select-panel .mat-option:active,
.mat-autocomplete-panel .mat-option:active {
  background: #e8fcf3;
  color: #333333;
}
.mat-select-panel .mat-option:active .mat-option-text,
.mat-autocomplete-panel .mat-option:active .mat-option-text {
  color: #171717;
}
.mat-select-panel .mat-option:active .mat-primary .mat-pseudo-checkbox-checked,
.mat-select-panel .mat-option:active .mat-primary .mat-pseudo-checkbox-indeterminate,
.mat-autocomplete-panel .mat-option:active .mat-primary .mat-pseudo-checkbox-checked,
.mat-autocomplete-panel .mat-option:active .mat-primary .mat-pseudo-checkbox-indeterminate {
  background-color: #171717;
}
.mat-select-panel .mat-option:active .mat-pseudo-checkbox,
.mat-autocomplete-panel .mat-option:active .mat-pseudo-checkbox {
  color: #171717;
}
.mat-select-panel .mat-option-text,
.mat-autocomplete-panel .mat-option-text {
  font-size: 10px;
  line-height: 18px;
  color: #171717;
}
@media screen and (min-width: 320px) {
  .mat-select-panel .mat-option-text,
.mat-autocomplete-panel .mat-option-text {
    font-size: 14px;
    line-height: calc(14px + 10px);
  }
}

.mat-form-field-hide-placeholder .mat-input-element::placeholder {
  display: block !important;
  color: #a3a3a3 !important;
  -webkit-text-fill-color: #a3a3a3 !important;
}

input.mat-form-field-autofill-control {
  padding-right: 55px;
}

.mat-checkbox label,
.mat-radio-button label {
  margin-bottom: 1px;
}
.mat-checkbox .mat-checkbox-frame,
.mat-checkbox .mat-radio-outer-circle,
.mat-radio-button .mat-checkbox-frame,
.mat-radio-button .mat-radio-outer-circle {
  border-width: 1px;
  border-color: #000000;
}
.mat-checkbox .mat-checkbox-inner-container,
.mat-radio-button .mat-checkbox-inner-container {
  width: 18px;
  height: 18px;
}
.mat-checkbox .mat-checkbox-inner-container:hover .mat-checkbox-frame, .mat-checkbox .mat-checkbox-inner-container:focus-visible .mat-checkbox-frame,
.mat-radio-button .mat-checkbox-inner-container:hover .mat-checkbox-frame,
.mat-radio-button .mat-checkbox-inner-container:focus-visible .mat-checkbox-frame {
  border-color: #18c36d;
}
.mat-checkbox .mat-checkbox-inner-container:hover .mat-checkbox-persistent-ripple,
.mat-checkbox .mat-checkbox-inner-container:hover .mat-ripple-element, .mat-checkbox .mat-checkbox-inner-container:focus-visible .mat-checkbox-persistent-ripple,
.mat-checkbox .mat-checkbox-inner-container:focus-visible .mat-ripple-element,
.mat-radio-button .mat-checkbox-inner-container:hover .mat-checkbox-persistent-ripple,
.mat-radio-button .mat-checkbox-inner-container:hover .mat-ripple-element,
.mat-radio-button .mat-checkbox-inner-container:focus-visible .mat-checkbox-persistent-ripple,
.mat-radio-button .mat-checkbox-inner-container:focus-visible .mat-ripple-element {
  display: none;
}
.mat-checkbox .mat-checkbox-inner-container:active .mat-checkbox-frame,
.mat-radio-button .mat-checkbox-inner-container:active .mat-checkbox-frame {
  border-color: #171717;
}
.mat-checkbox .mat-checkbox-inner-container:active .mat-checkbox-persistent-ripple,
.mat-checkbox .mat-checkbox-inner-container:active .mat-ripple-element,
.mat-radio-button .mat-checkbox-inner-container:active .mat-checkbox-persistent-ripple,
.mat-radio-button .mat-checkbox-inner-container:active .mat-ripple-element {
  display: none;
}

.mat-radio-button {
  margin: 0 !important;
}
.mat-radio-group .mat-radio-button {
  margin: 16px 16px 0 !important;
}
.mat-radio-button .mat-radio-outer-circle,
.mat-radio-button .mat-radio-inner-circle,
.mat-radio-button .mat-radio-container {
  width: 15px;
  height: 15px;
}
.mat-radio-button .mat-radio-inner-circle {
  transform: scale(0);
}
.mat-radio-button .mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.5);
}
.mat-radio-button .mat-radio-ripple {
  opacity: 0 !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #333333;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background:hover, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background:focus-visible,
.mat-checkbox-checked.mat-accent .mat-checkbox-background:hover,
.mat-checkbox-checked.mat-accent .mat-checkbox-background:focus-visible {
  background-color: #18c36d;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background:active,
.mat-checkbox-checked.mat-accent .mat-checkbox-background:active {
  background-color: #171717;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #000000;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #000000;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  display: none;
}

.mat-pseudo-checkbox {
  color: #333333;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #333333;
}
.mat-checkbox-indeterminate.mat-accent:hover .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent:focus-visible .mat-checkbox-background,
.mat-checkbox-checked.mat-accent:hover .mat-checkbox-background,
.mat-checkbox-checked.mat-accent:focus-visible .mat-checkbox-background {
  background-color: #18c36d;
}
.mat-checkbox-indeterminate.mat-accent:active .mat-checkbox-background,
.mat-checkbox-checked.mat-accent:active .mat-checkbox-background {
  background-color: #171717;
}

.mat-checkbox-disabled {
  pointer-events: none;
}

.mat-checkbox-indeterminate.mat-accent.mat-checkbox-disabled .mat-checkbox-background,
.mat-checkbox-checked.mat-accent.mat-checkbox-disabled .mat-checkbox-background {
  background-color: #a3a3a3;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #a3a3a3;
}

.mat-checkbox--rounded .mat-checkbox-frame,
.mat-checkbox--rounded .mat-checkbox-background {
  border-radius: 50%;
}
.mat-checkbox--rounded .mat-checkbox-background {
  width: 8px;
  height: 8px;
  margin: auto;
}
.mat-checkbox--rounded .mat-checkbox-checkmark {
  display: none;
}

.mat-autocomplete-panel-above {
  margin-bottom: 0;
}

.mat-select-panel-wrap {
  padding: 0 8px;
  margin-top: -5px;
}

.cdk-overlay-pane::-webkit-scrollbar,
.mat-autocomplete-panel::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.cdk-overlay-pane::-webkit-scrollbar-track,
.mat-autocomplete-panel::-webkit-scrollbar-track {
  background-color: #efefef;
}
.cdk-overlay-pane::-webkit-scrollbar-thumb,
.mat-autocomplete-panel::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
}
.cdk-overlay-pane::-webkit-scrollbar-thumb:hover,
.mat-autocomplete-panel::-webkit-scrollbar-thumb:hover {
  background-color: #969696;
}

.cdk-column-select {
  max-width: 18px;
  padding-right: 0;
}

.mat-optgroup .mat-optgroup-label {
  height: inherit;
  margin: 5px 0;
  padding: 8px 12px;
  background: #efefef;
  line-height: inherit;
  font-weight: 500;
}

.mat-radio-label {
  margin: 0;
}

.mat-radio-button {
  margin: 16px;
}

.mat-radio-group {
  margin: -16px;
}

.intl-tel-input::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.intl-tel-input::-webkit-scrollbar-track {
  background-color: #efefef;
}
.intl-tel-input::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
}
.intl-tel-input::-webkit-scrollbar-thumb:hover {
  background-color: #969696;
}
.intl-tel-input .selected-flag {
  outline: 0;
}
.intl-tel-input .country-list .country {
  font-size: 10px;
  line-height: 18px;
  max-width: 442px;
  padding: 6px 12px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-width: 320px) {
  .intl-tel-input .country-list .country {
    font-size: 14px;
    line-height: calc(14px + 10px);
  }
}
.intl-tel-input .country-list .flag-box,
.intl-tel-input .country-list .country-name {
  margin-right: 12px;
}

.intl-tel-input.allow-dropdown .selected-flag,
.intl-tel-input.separate-dial-code .selected-flag {
  width: 55px;
  padding: 0 0 0 12px;
}

.intl-tel-input .selected-flag .iti-arrow {
  right: 8px;
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type=text],
.intl-tel-input.allow-dropdown input[type=tel],
.intl-tel-input.separate-dial-code input,
.intl-tel-input.separate-dial-code input[type=text],
.intl-tel-input.separate-dial-code input[type=tel] {
  padding: 6px 12px 3px 60px;
}

.iti--allow-dropdown input[type=tel] {
  padding-left: 84px !important;
}

.iti__selected-flag {
  padding: 0 24px 0 12px !important;
}
body.rtl .iti__selected-flag {
  padding: 0 12px 0 24px !important;
}

.intl-tel-input .country-list,
.intl-tel-input .flag-container {
  width: 100%;
}

.uni-form-field.is-disabled .iti__flag-container:hover,
.uni-form-field.is-disabled .iti__flag-container:hover .iti__selected-flag {
  background-color: inherit;
  cursor: auto;
  outline: 0;
}

.iti__flag-container {
  border-right: 2px solid #c2c2c2;
}
.is-disabled .iti__flag-container {
  border-right: 2px solid #898989;
}
body.rtl .iti__flag-container {
  border-right: none;
  border-left: 2px solid #c2c2c2;
}
.is-disabled body.rtl .iti__flag-container {
  border-right: none;
  border-left: 2px solid #898989;
}

.iti__arrow {
  display: inline-block;
  height: 12px;
  margin-left: 10px;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 400;
  color: #898989;
  font-family: "Font Awesome 6 Pro";
  border: none;
}
.iti__arrow::before {
  content: "";
}

.iti__arrow.iti__arrow--up::before {
  content: "";
}

.angular-tree-component {
  font-weight: 500;
}
.angular-tree-component .tree-node-level-2,
.angular-tree-component .tree-node-level-3 {
  text-transform: initial;
}
.angular-tree-component .tree-node-level-3 .node-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: none;
  font-weight: 400;
}
.angular-tree-component .tree-node-level-2.tree-node-expanded .node-wrapper:first-of-type {
  border-bottom: none;
}
.angular-tree-component .tree-node {
  cursor: auto;
}
.angular-tree-component .node-wrapper {
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
}
.angular-tree-component .node-wrapper .node-content-wrapper-active,
.angular-tree-component .node-wrapper .node-content-wrapper-focused,
.angular-tree-component .node-wrapper .node-content-wrapper:hover {
  background: transparent;
  box-shadow: none;
}
.angular-tree-component .node-wrapper tree-node-expander {
  margin-left: auto;
  order: 3;
  cursor: pointer;
}
.angular-tree-component .node-wrapper tree-node-expander .toggle-children-wrapper-expanded .toggle-children {
  transform: rotate(180deg);
}
.angular-tree-component .node-wrapper tree-node-expander .toggle-children {
  height: unset;
  width: unset;
  background-image: none;
}
.angular-tree-component .node-wrapper tree-node-expander .toggle-children:before {
  display: block;
  content: "";
  font-size: 12px;
  font-family: "Font Awesome 6 Pro";
  font-weight: 500;
  color: #16b163;
}

.mat-slider .mat-slider-track-background {
  background-color: #ffffff !important;
}
.mat-slider .mat-slider-track-wrapper,
.mat-slider .mat-slider-track-fill {
  height: 4px !important;
  cursor: pointer;
}
.mat-slider .mat-slider-thumb {
  background-color: #16b163 !important;
  border-color: #16b163 !important;
}

.mat-slider-horizontal {
  height: 32px !important;
}
.mat-slider-horizontal .mat-slider-wrapper {
  top: 15px !important;
}

.mat-tooltip {
  box-sizing: content-box;
}

.mat-tooltip--info {
  position: relative;
  background-color: #e8fcf3;
  box-shadow: 0 5px 12px 2px rgba(0, 0, 0, 0.12);
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.07em;
  line-height: 20px;
  color: #333333 !important;
  overflow: visible !important;
}
.mat-tooltip--info::after {
  content: "";
  position: absolute;
  right: calc(50% - 10px);
  top: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #e8fcf3 transparent transparent transparent;
}

.mat-tooltip-trigger {
  color: #18c36d !important;
}

.cdk-overlay-container {
  z-index: 1999;
}

body.rtl .country-list .flag-box,
body.rtl .country-list .country-name {
  margin-right: 0;
  margin-left: 12px;
}
body.rtl input.mat-form-field-autofill-control {
  padding-right: 14px;
  padding-left: 55px;
  text-align: right;
}
body.rtl .intl-tel-input .selected-flag .iti-arrow {
  right: auto;
  left: 8px;
}
body.rtl .iti--allow-dropdown .iti__flag-container,
body.rtl .iti--separate-dial-code .iti__flag-container {
  right: 0;
  left: auto;
}
body.rtl .iti--allow-dropdown input,
body.rtl .iti--allow-dropdown input[type=text],
body.rtl .iti--allow-dropdown input[type=tel],
body.rtl .iti--separate-dial-code input,
body.rtl .iti--separate-dial-code input[type=text],
body.rtl .iti--separate-dial-code input[type=tel] {
  padding-right: 84px !important;
  padding-left: 8px !important;
  margin-right: 0;
}
body.rtl .iti__arrow {
  margin-right: 6px;
  margin-left: 0;
}
body.rtl .iti__country {
  display: flex;
  align-items: center;
  text-align: right;
}
body.rtl .iti__flag-box,
body.rtl .iti__country-name {
  margin-right: 0;
  margin-left: 6px;
}
body.rtl .mat-calendar-arrow {
  margin: 0 5px 0 0;
}
body.rtl .mat-calendar-body-label {
  text-align: right;
}
body.rtl .mat-calendar-next-button,
body.rtl .mat-calendar-previous-button {
  transform: scaleX(-1);
}
body.rtl .mat-datepicker-content .mat-calendar {
  direction: ltr;
}
body.rtl .iti__dial-code {
  order: 1;
}
body.rtl .iti input {
  text-align: right;
  direction: ltr;
  unicode-bidi: embed;
}
body.rtl .mat-radio-label-content {
  padding-right: 8px !important;
  padding-left: 0 !important;
}
body.rtl .mat-select-arrow {
  margin-right: 0;
  margin-left: 13px;
}
body.rtl .mat-select-arrow-wrapper {
  padding-right: 8px;
  padding-left: 0;
}
body.rtl .mat-option,
body.rtl .mat-optgroup-label,
body.rtl .mat-select-placeholder,
body.rtl .mat-select-value {
  text-align: right;
  direction: ltr;
}
body.rtl .mat-option {
  justify-content: flex-end;
}
body.rtl .uni-language-switcher .mat-select-value {
  text-align: left;
}
body.rtl .mat-tooltip {
  direction: rtl;
}
body.rtl .mat-tooltip--info::after {
  right: auto;
  left: calc(50% - 10px);
}

.mat-calendar-body-selected {
  border-color: #18c36d;
  color: #000000;
  background-color: #e8fcf3;
  font-weight: 500;
}

.mat-calendar-body-begin-range:not(.mat-calendar-body-end-range),
.mat-calendar-body-end-range:not(.mat-calendar-body-begin-range),
.mat-calendar-cell-semi-selected {
  background-color: rgba(22, 177, 99, 0.1);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: #4ce599;
  color: #333333;
  background-color: #ffffff;
}

.mat-calendar-body-in-range::before {
  background-color: #efefef;
}

.mat-calendar-arrow {
  display: inline-block;
  height: 14px !important;
  width: 20px !important;
  margin-left: 10px;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 400;
  color: #898989;
  font-family: "Font Awesome 6 Pro";
  border: none !important;
}
.mat-calendar-arrow::before {
  content: "";
}
.mat-calendar-arrow.mat-calendar-invert::before {
  content: "";
}

.mat-calendar-body-label {
  opacity: 0;
}

.mat-button-focus-overlay {
  background-color: transparent;
}

:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-semi-selected,
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-semi-selected,
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-semi-selected {
  color: #333333;
}

.mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button, .mat-datepicker-toggle {
  color: #333333;
}

.mat-datepicker-content button:hover, .mat-datepicker-content button:focus-visible, .mat-datepicker-content .btn:hover, .mat-datepicker-content .btn:focus-visible {
  color: #18c36d;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  border-color: #18c36d;
  background-color: #ffffff;
  font-weight: 500;
}

.mat-calendar-cell-over > .mat-calendar-body-cell-content {
  border-color: #18c36d;
}

.mat-datepicker-content {
  border-radius: 4px;
  box-shadow: 0 2px 4px 2px rgba(51, 51, 51, 0.1);
}

.mat-calendar .mat-calendar-body-cell-content {
  border-radius: 4px;
}

@keyframes animation-pulse {
  0% {
    border-color: #e0e0e0;
    background-color: #efefef;
  }
  50% {
    background-color: #e8fcf3;
    border-color: #4ce599;
  }
  100% {
    border-color: #e0e0e0;
    background-color: #efefef;
  }
}
.uni-input,
input.mat-input,
input.uni-input {
  width: 100%;
  height: 44px;
  padding: 14px;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  color: #000000;
  background-color: #ffffff;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  line-height: initial;
  transition: 0.2s color ease-in-out;
  box-sizing: border-box;
}
@media screen and (min-width: 1200px) {
  .uni-input,
input.mat-input,
input.uni-input {
    font-size: 14px;
  }
}
.uni-input::placeholder,
input.mat-input::placeholder,
input.uni-input::placeholder {
  color: #898989;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  line-height: initial;
}
.uni-input:not(.mat-form-field),
input.mat-input:not(.mat-form-field),
input.uni-input:not(.mat-form-field) {
  padding: 12px 12px 14px;
}
@media screen and (min-width: 1200px) {
  .uni-input,
input.mat-input,
input.uni-input {
    font-size: 14px;
  }
}
.uni-input:hover,
input.mat-input:hover,
input.uni-input:hover {
  background-color: #f7f6f6;
  border-color: #c2c2c2;
  border: 1px solid #c2c2c2;
}
.uni-input:hover:not(.mat-select.uni-input):not(.mat-form-field .uni-input),
input.mat-input:hover:not(.mat-select.uni-input):not(.mat-form-field .uni-input),
input.uni-input:hover:not(.mat-select.uni-input):not(.mat-form-field .uni-input) {
  background-color: #f7f6f6;
}
.uni-input:disabled,
input.mat-input:disabled,
input.uni-input:disabled {
  color: #898989;
  background-color: #efefef;
  border-color: #e0e0e0;
  pointer-events: none;
}
.uni-input:active,
input.mat-input:active,
input.uni-input:active {
  border-color: #1d76fc;
  color: #171717;
}
.uni-input:focus-visible,
input.mat-input:focus-visible,
input.uni-input:focus-visible {
  border-color: #1d76fc;
  outline: #1d76fc;
}
.uni-input.is-highlighted,
input.mat-input.is-highlighted,
input.uni-input.is-highlighted {
  color: #333333;
  animation: 2s animation-pulse infinite;
}
.mat-form-field .uni-input,
.mat-form-field input.mat-input,
.mat-form-field input.uni-input {
  border: none;
}
.rtl .uni-datepicker .uni-input, .rtl :host .uni-datepicker .uni-input,
.rtl .uni-datepicker input.mat-input,
.rtl :host .uni-datepicker input.mat-input,
.rtl .uni-datepicker input.uni-input,
.rtl :host .uni-datepicker input.uni-input {
  direction: ltr;
  text-align: right;
}

input.mat-input-element {
  margin-top: 0 !important;
}

textarea.uni-input {
  min-height: 140px;
}
textarea.uni-input:read-only {
  color: #6b6b6b;
  background-color: #efefef;
  pointer-events: none;
}

.uni-input--error,
.uni-input--error:focus-visible,
input.uni-input--error,
input.uni-input--error:focus-visible {
  border-color: #f86d68;
}